.circular-progress-bar {
  display: flex;
  width: var(--circular-progress-bar-size);
  height: var(--circular-progress-bar-size);
  border-radius: 50%;
  background: conic-gradient(
    var(--circular-progress-bar-color) var(--circular-progress-bar-progress),
    white 0deg
  );
}

.circular-progress-bar::after {
  content: "";
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: var(--circular-progress-bar-width);
  border-radius: 50%;
  background: white;
}
