@import "tailwindcss/base";
@import "tailwindcss/components";
@import "./utilities.css";
@import "./rs-slider-fyve.css";
@import "./datepicker-styles.css";
@import "./alert.css";
@import "@fyvedev/fe-storybook/dist/esm/index.css";

@layer base {
  html {
    @apply scroll-smooth;
  }

  html,
  body {
    font-size: calc(calc(100vw / 390) * 16);
    @apply bg-pitchy-black-100;
  }

  h1 {
    @apply heading-40 text-pitchy-black-600 font-sans;
  }

  h2 {
    @apply heading-30 text-pitchy-black-600 font-sans;
  }

  h3 {
    @apply heading-21 text-pitchy-black-600 font-sans;
  }

  h4,
  h4.text-inherit span {
    @apply heading-18 text-pitchy-black-600 font-sans;
  }

  h6 {
    @apply button-12 text-pitchy-black-600;
  }

  p,
  .p {
    @apply body-15 text-pitchy-black-600 font-sans;
  }

  button {
    @apply button-16 font-sans;
  }

  label,
  .label {
    @apply label-14 text-pitchy-black-600 font-sans;
    cursor: inherit;
  }

  span,
  .span {
    @apply caption-12 text-pitchy-black-400 font-sans;
  }

  a {
    @apply button-16 text-sapphire-700 font-sans;
  }

  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  textarea,
  input {
    @apply body-15 text-pitchy-black-600 font-sans;
    background-color: unset;
  }

  input::placeholder,
  textarea::placeholder {
    @apply body-15 text-pitchy-black-400 font-sans;
  }

  hr {
    @apply border-pitchy-black-300;
  }

  @media screen and (min-width: 480.1px) {
    html,
    body {
      font-size: calc(calc(100vw / 1440) * 16);
    }
  }
}
