.gradient-error {
  background: linear-gradient(
      0deg,
      rgba(211, 0, 38, 0.1),
      rgba(211, 0, 38, 0.1)
    ),
    #ffffff;
}

.gradient-success-10 {
  background: linear-gradient(
      0deg,
      rgba(20, 211, 79, 0.1),
      rgba(20, 211, 79, 0.1)
    ),
    #ffffff;
}

.gradient-success-5 {
  background: linear-gradient(
      0deg,
      rgba(20, 211, 79, 0.05),
      rgba(20, 211, 79, 0.05)
    ),
    #ffffff;
}

.gradient-success-20 {
  background: linear-gradient(
      0deg,
      rgba(20, 211, 79, 0.2),
      rgba(20, 211, 79, 0.2)
    ),
    #ffffff;
}

.gradient-sapphire-700 {
  background: linear-gradient(
      0deg,
      rgba(38, 112, 248, 0.1),
      rgba(38, 112, 248, 0.1)
    ),
    #ffffff;
}
